import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { CircularProgress, ClickAwayListener, Popper } from '@mui/material';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useRouter } from 'next/router';
import formatLocation from '@ictlife/core/utils/formatLocation';
import Geocode from 'react-geocode';
import SnackInfo from '@ictlife/core/components/SnackInfo';
import AppToolTip from '../shared/ToolTip';
import { DEFAULT_LOCATION } from '../../constants';
import useSuperCategoryAndLocation from '../../hooks/useSuperCategoryAndLocation';
import s from './styles/search.module.scss';

const Search = ({ location, onSearch }) => {
  const router = useRouter();
  const {
    search,
    section,
    selectedCategory,
    selectedSubCategory,
    selectedSubSubCategory,
    happening,
  } = router.query;
  const { superCategory, address, superCategoryAndLocation, loadingLocation } =
    useSuperCategoryAndLocation();
  const [openSearchLocations, setOpenSearchLocations] = useState({
    open: false,
    anchor: null,
  });

  const [snackInfo, setSnackInfo] = useState({
    open: false,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: 'info',
    autoHideDuration: 5000,
  });

  const locationSearchRef = useRef();

  const [searchQuery, setSearchQuery] = useState(router.query.search);

  useEffect(() => {
    setSearchQuery(router.query.search || '');
  }, [router.query.search]);

  const searchRouterOptions = useMemo(() => {
    const baseQueryOptions = {
      ...(section && { section }),
      ...(search && { search }),
      ...(happening && { happening }),
    };

    if (selectedCategory) {
      return {
        pathname: '/[superCategory]/category/[selectedCategory]',
        query: {
          superCategory: superCategoryAndLocation,
          selectedCategory,
          ...baseQueryOptions,
        },
      };
    }

    if (selectedSubCategory) {
      return {
        pathname:
          '/[superCategory]/category/[selectedCategory]/[selectedSubCategory]',
        query: {
          superCategory: superCategoryAndLocation,
          selectedCategory,
          selectedSubCategory,
          ...baseQueryOptions,
        },
      };
    }

    if (selectedSubSubCategory) {
      return {
        pathname:
          '/[superCategory]/category/[selectedCategory]/[selectedSubCategory]',
        query: {
          superCategory: superCategoryAndLocation,
          selectedCategory,
          selectedSubCategory,
          selectedSubSubCategory,
          ...baseQueryOptions,
        },
      };
    }

    return {
      pathname: '/[superCategory]',
      query: {
        superCategory: superCategoryAndLocation,
        ...baseQueryOptions,
      },
    };
  }, [
    happening,
    search,
    section,
    selectedCategory,
    selectedSubCategory,
    selectedSubSubCategory,
    superCategoryAndLocation,
  ]);

  const handleSearchQueryUpdate = useCallback(() => {
    router.push({
      pathname: searchRouterOptions.pathname,
      query: {
        ...searchRouterOptions.query,
        ...(searchQuery && { search: searchQuery }),
      },
    });
  }, [
    router,
    searchQuery,
    searchRouterOptions.pathname,
    searchRouterOptions.query,
  ]);

  const getLocationSuccess = useCallback(
    pos => {
      var crd = pos.coords;
      Geocode.setApiKey(process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY);

      Geocode.setLocationType('ROOFTOP');
      Geocode.fromLatLng(crd.latitude, crd.longitude).then(
        response => {
          const { city, country } = formatLocation(response);

          const location = `${city}-${country}`
            .replace(/\s/g, '-')
            .toLowerCase();

          router.push({
            pathname: searchRouterOptions.pathname,
            query: {
              ...searchRouterOptions.query,
              superCategory: `${superCategory}-in-${location}`,
            },
          });
        },
        () => {
          // if function above fails
        }
      );
      setSearchInputAddress('');
      setOpenSearchLocations(openSearch => ({ ...openSearch, open: false }));
    },
    [
      router,
      searchRouterOptions.pathname,
      searchRouterOptions.query,
      superCategory,
    ]
  );

  const [searchInputAddress, setSearchInputAddress] = useState('');

  const handleChangeLocationSearchInput = address => {
    setSearchInputAddress(address);
  };

  const handleSelectLocation = locationObject => {
    const terms = locationObject.terms.slice(0, 2);
    const locationCode = terms
      .map(term => term.value)
      .join('-')
      .replace(/\s/g, '-')
      .toLowerCase();
    setSearchInputAddress('');
    setOpenSearchLocations(openSearch => ({ ...openSearch, open: false }));
    router.push({
      pathname: searchRouterOptions.pathname,
      query: {
        ...searchRouterOptions.query,
        superCategory: `${superCategory}-in-${locationCode}`,
      },
    });
  };

  function getLocationError(err) {
    setSnackInfo(snackInfo => ({
      ...snackInfo,
      message: `${err.message}. Type location to search.`,
      open: true,
      severity: 'error',
    }));
    locationSearchRef.current?.focus();
  }

  var userPositionOptions = useMemo(
    () => ({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }),
    []
  );

  const getUserCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        getLocationSuccess,
        getLocationError,
        userPositionOptions
      );
    }
  };

  return (
    <>
      <div
        className={cn({
          [s.searchHolder]: true,
          [s.navSearchHolder]: location === 'topbar',
        })}
      >
        <div className={s.searchInput}>
          <input
            placeholder="What are you looking for ?"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            onKeyPress={e => {
              if (e.code === 'Enter') {
                handleSearchQueryUpdate();
                onSearch && onSearch();
              }
            }}
          />
          {searchQuery && (
            <div
              className={s.clear}
              onClick={() => {
                setSearchQuery('');
                delete router.query['search'];
                router.push({
                  query: router.query,
                });
                onSearch && onSearch();
              }}
            >
              <span className="material-icons">&#xe5cd;</span>
            </div>
          )}
        </div>

        <AppToolTip title={address.length > 20 ? address : ''} placement="top">
          <div
            className={s.searchLocation}
            onClick={e => {
              const anchor = e.currentTarget;
              setOpenSearchLocations(openSearch => ({
                anchor,
                open: !openSearch.open,
              }));
              setTimeout(() => {
                locationSearchRef.current?.focus();
              }, 200);
            }}
          >
            <span className="material-icons">&#xe55f;</span>
            <span>
              {address.slice(0, 20)}
              {address.length > 20 && ' ...'}
            </span>
          </div>
        </AppToolTip>

        <div className={s.searchWrapper}>
          <div
            className={s.searchButton}
            onClick={() => {
              handleSearchQueryUpdate();
              onSearch && onSearch();
            }}
          >
            {loadingLocation ? (
              <CircularProgress size={15} />
            ) : (
              <span className="material-icons">&#xe5c8;</span>
            )}
          </div>
        </div>
      </div>
      <Popper
        open={openSearchLocations.open}
        anchorEl={openSearchLocations.anchor}
        style={{ zIndex: 5000 }}
      >
        <ClickAwayListener
          onClickAway={() =>
            openSearchLocations.open &&
            setOpenSearchLocations(openSearchLocations => ({
              ...openSearchLocations,
              open: false,
            }))
          }
        >
          <div>
            <PlacesAutocomplete
              value={searchInputAddress}
              onChange={handleChangeLocationSearchInput}
              searchOptions={{ types: ['political'] }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className={cn(s.searchLocations, 'app-form')}>
                  <input
                    {...getInputProps({
                      placeholder: 'Search a specific location',
                    })}
                    ref={locationSearchRef}
                  />
                  <div className={s.suggestionsContainer}>
                    {loading && <div className={s.loading}>loading...</div>}
                    {suggestions.map(suggestion => {
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.description}
                          className={cn({
                            [s.suggestion]: true,
                            [s.active]: suggestion.active,
                          })}
                          onClick={() => handleSelectLocation(suggestion)}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                    <div
                      className={cn({
                        [s.suggestion]: true,
                      })}
                      onClick={getUserCurrentLocation}
                    >
                      <span
                        className="material-icons"
                        style={{ marginRight: '0.4em' }}
                      >
                        &#xe569;
                      </span>
                      <span>Near Me</span>
                    </div>
                    <div
                      className={cn({
                        [s.suggestion]: true,
                      })}
                      onClick={() => {
                        setOpenSearchLocations(openSearch => ({
                          ...openSearch,
                          open: false,
                        }));

                        router.push({
                          pathname: searchRouterOptions.pathname,
                          query: {
                            ...searchRouterOptions.query,
                            superCategory,
                          },
                        });
                      }}
                    >
                      <span
                        className="material-icons"
                        style={{ marginRight: '0.4em' }}
                      >
                        &#xe80b;
                      </span>
                      <span>{DEFAULT_LOCATION.address}</span>
                    </div>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </ClickAwayListener>
      </Popper>
      <SnackInfo
        snackInfo={snackInfo}
        handleClose={() =>
          setSnackInfo(snackInfo => ({
            ...snackInfo,
            open: false,
          }))
        }
      />
    </>
  );
};

export default Search;
