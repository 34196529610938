const formatLocation = locationData => {
  const address = locationData.results[0].formatted_address;
  let city, state, country;
  for (let i = 0; i < locationData.results[0].address_components.length; i++) {
    for (
      let j = 0;
      j < locationData.results[0].address_components[i].types.length;
      j++
    ) {
      switch (locationData.results[0].address_components[i].types[j]) {
        case 'locality':
          city = locationData.results[0].address_components[i].long_name;
          break;
        case 'administrative_area_level_1':
          state = locationData.results[0].address_components[i].long_name;
          break;
        case 'country':
          country = locationData.results[0].address_components[i].long_name;
          break;
      }
    }
  }

  return {
    city,
    state,
    country,
    address,
  };
};

export default formatLocation;
